<template>
  <section class="no-code-received">
    <h1 class="title">
      {{ noVerification.noVerification_title }}
    </h1>
    <div id="container"></div>
  </section>
</template>

<script>
import { APP_CONFIG } from "@/utils/appConstant";
import { mapState } from "vuex";
// import { noVerification } from "./language";

export default {
  data() {
    return {
      apply: this.$commonFunc.getUrlParams()?.apply,
      appId: this.$commonFunc.getUrlAppId(),
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    noVerification() {
      return this.languagePackage[this.tabIndex];
    },
    data() {
      const noVerification = this.languagePackage[this.tabIndex];
      const arr = [];
      if (this.apply !== "logOff" || APP_CONFIG[this.appId].region) {
        arr.push({ desc: noVerification.noVerification_desc1 });
      }
      const newArr = arr.concat([
        // { desc: noVerification.noVerification_desc1 },
        { desc: noVerification.noVerification_desc2 },
        { desc: noVerification.noVerification_desc3 },
        { desc: noVerification.noVerification_desc4 },
        { desc: noVerification.noVerification_desc5 },
      ]);
      return newArr;
    },
  },
  created() {
    // document.title = "No verification code received";
  },
  mounted() {
    let html = "";
    for (let i = 0; i < this.data.length; i++) {
      const item = this.data[i];
      html += `<dl style="display:flex;margin-top:20px"><dt style="display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;">${i + 1}</dt><dd style="display: flex;
    line-height: 20px;
    margin-left: 8px;
    margin-right:8px">${item.desc}</dd></dl>`;
    }
    const container = document.getElementById("container");
    container.innerHTML = html;
  },
};
</script>

<style lang="less" scoped>
.no-code-received {
  padding: 0 16px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  .title {
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0 11px 0;
  }
}
.text {
  display: flex;
  margin-top: 20px;
  .dt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
  }
  .dd {
    display: flex;
    line-height: 20px;
    margin-left: 8px;
  }
}
</style>
